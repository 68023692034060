import { createUseStyles } from "react-jss";
import background from '../assets/background.jpg'

export const useStyles = createUseStyles({
  app: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',
    minHeight: '100vh',
    background: `url(${background}) no-repeat center right fixed`,
    backgroundSize: 'cover',
    '&:before': {
      content: '\'\'',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backdropFilter: 'blur(4px)',
    }
  },
  outlet: {
    color: '#FFFFFF',
    minWidth: '20vw',
    padding: '1rem',
    zIndex: 1,
  }
})
