import React from 'react';
import {useStyles} from './style';
import { Outlet } from 'react-router-dom';


function App() {
  const classes = useStyles();
  return (
    <div className={classes.app}>
      <div className={classes.outlet}>
        <Outlet />
      </div>
    </div>
  );
}

export default App;
