import { useCooks } from '../../hooks/useCook.hook';
import { FC, useRef, useState } from 'react';
import { useStyles } from './style';
import { Cook } from '../../models/Cook';

export const MainPage: FC = () => {
  const [cooks, loading, error] = useCooks()
  const classes = useStyles();
  const [currentCook, setCurrentCook] = useState<Cook>();
  const [selectOpen, setSelectOpen] = useState(false);
  const inputRef = useRef<any>()

  if (loading) {
    return <div>Loading</div>
  }
  if (error) {
    return <div>Error</div>
  }

  if (!cooks || !cooks?.length) {
    return <div>No cooks</div>
  }

  if (!currentCook) {
    setCurrentCook(cooks[0])
  }

  const currentIndex = cooks.findIndex(cook => cook.id === currentCook?.id);
  const nextIndex = (currentIndex + 1) % cooks.length;

  const nextCook: Cook = cooks[nextIndex];
  // document.addEventListener("mousedown", () => setSelectOpen(false));

  return (
    <div className={classes.wrapper}>
      <p className={classes.header}>
        If
        <div className={classes.dropDownWrapper} onClick={() => setSelectOpen(!selectOpen)}>
          <span className={classes.marked}>
            &lt;{currentCook?.name}&gt;
          </span>
          <ul className={`${selectOpen ? 'open ' : ''}${classes.dropDown}`}>
              {cooks.map((cook) => <li onClick={() => {
                setCurrentCook(cook);
                setSelectOpen(false)
              }}>{cook.name}</li>)}
          </ul>
        </div> cooked last time, then&nbsp;
        <span className={classes.marked}>{nextCook.name}</span> should cook now
      </p>
    </div>
  )
}
