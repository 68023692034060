import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

let config = {
  apiKey: "AIzaSyA_1S2EMrDB_e3EZh8JvMs7vj-hXAAoHcA",
  authDomain: "whocook-fae8e.firebaseapp.com",
  projectId: "whocook-fae8e",
  storageBucket: "whocook-fae8e.appspot.com",
  messagingSenderId: "761027151049",
  appId: "1:761027151049:web:bcd184a4e33948be133918",
  measurementId: "G-1N160KS9M3"
};

const app = initializeApp(config);

export const firestore = getFirestore(app);
export const analytics = getAnalytics(app);
