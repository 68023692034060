import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  header: {
    fontFamily: 'Dosis',
    letterSpacing: '.15em',
    maxWidth: '500px',
    fontSize: '3em',
    textAlign: 'center',
    color: '#BDBDBD',
    lineHeight: 1.5,
    textShadow: '1px 1px #666, 3px 3px #000',
  },
  dropDownWrapper: {
    display: 'inline-block',
    position: 'relative',
  },
  dropDown: {
    position: 'absolute',
    top: '100%',
    left: 0,
    minWidth: '100%',
    backdropFilter: 'blur(4px)',
    borderRadius: '.5em',
    padding: 0,
    margin: 0,
    paddingLeft: '0.8em',
    paddingRight: '0.8em',
    boxSizing: 'border-box',
    background: 'rgba(0,150,136,0.25)',
    color: '#009688',
    fontSize: '1.3rem',
    fontWeight: 500,
    display: 'none',
    zIndex: 1000,
    border: '4px solid rgba(0,150,136,0.25)',
    '& li': {
      listStyle: 'none',
      cursor: 'pointer',
      marginBottom: '0.8em',
      borderTop: '2px solid rgba(0,150,136,0.25)',
      '&:first-child': {
        paddingTop: '0.8em',
        borderTop: 'none'
      },
    },
    '&.open': {
      display: 'block',
    }
  },
  marked: {
    padding: {
      left: '.25em',
      right: '.25em',
    },
    color: '#009688',
  },
})
