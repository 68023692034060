import { collection, DocumentData, WithFieldValue, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions, query, orderBy } from 'firebase/firestore';
import { firestore } from '../bootstrap/firebase';
import { Cook } from '../models/Cook';
import { useCollectionData } from 'react-firebase-hooks/firestore';

const cookConverter: FirestoreDataConverter<Cook> = {
  toFirestore(cook: WithFieldValue<Cook>): DocumentData {
    return { name: cook.name, order: cook.order };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Cook {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      ref: snapshot.ref,
      name: data.name,
      order: data.order,
    };
  },
};

const ref = collection(firestore, 'cooks').withConverter(cookConverter);
export const useCooks = () => useCollectionData(query(ref, orderBy('order', 'asc')));
