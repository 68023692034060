import { createBrowserRouter } from 'react-router-dom';
import App from './App/App';
import RouterError from './components/RouterError';
import { MainPage } from './pages/Main/Main';

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <RouterError />,
    children: [{
      path: '',
      element: <MainPage />
    }]
  },
]);
